import { inject } from '@angular/core';
import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpResponse,
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { KazmaRes } from '@core/model';
import { Router } from '@angular/router';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);
  // const translate = inject(LanguageService);
  let authorization = localStorage.getItem('token');
  // let acceptLanguage = translate.currentLanguage();
  //console.log(req + " " + authorization);
  req = req.clone({
    setHeaders: {
      Authorization: `Bearer ${authorization}`,
      'Accept-Language': 'acceptLanguage',
    },
  });

  return next(req).pipe(
    tap(
      () => {},
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            if (err instanceof HttpResponse) {
              const res = err.body as KazmaRes<any>;
              if (
                res.result &&
                res.result.statusCode == 0 &&
                res.result.statusMessage === 'Not Authorize'
              ) {
                console.error('Not Authorize');
                localStorage.clear();
                router.navigate(['/login']);
              }
            }

            return;
          }
          //handle 401
          console.error('Not Authorize');
          localStorage.clear();
          // localStorage.setItem('NotAuthorize', 'NotAuthorize');
          router.navigate(['/login']);
          window.location.reload();
        }
      }
    )
  );

  /*return next(req).pipe(
    tap((r) => {


      if (r instanceof HttpResponse) {
        const res = r.body as KazmaRes<any>;
        if (
          res.result &&
          res.result.statusCode == 0 &&
          res.result.statusMessage === 'Not Authorize'
        ) {
          console.error('Not Authorize');
          localStorage.clear();
          router.navigate(['/login']);
        }
      }
    })
  );*/
};
