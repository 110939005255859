import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {Title} from "@angular/platform-browser";
import { environment } from "@environments/environment";

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterOutlet],
})
export class AppComponent  {
  public favIcon: any;
  constructor( private title: Title) {
    this.title.setTitle(environment.PROJECT_NAME);
    this.favIcon = document.getElementById('favIcon');
    this.favIcon.href = environment.faviconUrl;

  }
}
